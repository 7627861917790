import axios from '@/api/axios';

//发送短信验证码
export const sendSmsCode = (data) => {
    return axios.request({url: "sms/send", method: 'post', data: data})
};

//请求手机验证码登录
export const phoneLogin = (data) => {
    return axios.request({url: "login/login", method: 'post', data: data})
};

//权益兑换
export const equityExchange = (data) => {
    return axios.request({url: "account/barcode", method: 'post', data: data})
};

//请求权益列表
export const getConvertList = (data) => {
    return axios.request({url: "account/interests", method: 'post', data: data})
};


//请求权益详情
export const getConvertDetailList = (data) => {
    return axios.request({url: "goods/list", method: 'post', data: data})
};

//请求产品详情
export const getProductDetail = (data) => {
    return axios.request({url: "goods/goodsdetails", method: 'post', data: data})
};
//请求订单详情
export const getOrderdetails = (data) => {
    return axios.request({url: "order/orderdetails", method: 'post', data: data})
};


//请求产品详情
export const getProductGroup = (data) => {
    return axios.request({url: "goods/bulletframe", method: 'post', data: data})
};


//请求订单列表
export const getOrderList = (data) => {
    return axios.request({url: "order/list", method: 'post', data: data})
};


//提交兑换礼品
export const submitProduct = (data) => {
    return axios.request({url: "goods/exchange", method: 'post', data: data})
};


//请求地址列表
export const getAddressList = (data) => {
    return axios.request({url: "account/addresslist", method: 'post', data: data})
};

//请求添加用户地址
export const addUserAddress = (data) => {
    return axios.request({url: "account/addressadd", method: 'post', data: data})
};

//请求删除用户地址
export const delUserAddress = (data) => {
    return axios.request({url: "account/addressdel", method: 'post', data: data})
};

//请求更新用户地址
export const updateUserAddress = (data) => {
    return axios.request({url: "account/addressupdate", method: 'post', data: data})
};

//请求更新用户默认地址
export const updateUserDefaultAddress = (data) => {
    return axios.request({url: "account/defaultaddress", method: 'post', data: data})
};

//请求查询用户地址详情
export const addressDetails = (data) => {
    return axios.request({url: "account/details", method: 'post', data: data})
};

//请求省份列表
export const getProvinceList = (data) => {
    return axios.request({url: "account/provincecitydistrict", method: 'post', data: data})
};

//获取使用时间
export const getUsedate = (data) => {
    return axios.request({url: "goods/usedate", method: 'post', data: data})
};

//获取使用时间
export const getAirportList = (data) => {
    return axios.request({url: "goods/airport", method: 'post', data: data})
};
